import React from "react"
import { Link } from "gatsby"
import { MenuInnerWrapper, MenuWrapper } from "./styles"
import LanguageSelection from "./LanguageSelection"
import useCurrentLang from "../../utils/useCurrentLang"

const MenuQuery = ({ setMenu, menu }) => {
  const { currentLang } = useCurrentLang() || {}

  return (
    <MenuWrapper open={menu}>
      <MenuInnerWrapper>
        {currentLang === "" && (
          <>
            <Link
              onClick={() => {
                var element = document.getElementById("about")
                if (element) {
                  element.scrollIntoView(true)
                }
                setMenu(false)
              }}
              to="/#about"
            >
              About
            </Link>
            <Link
              onClick={() => {
                var element = document.getElementById("history")
                if (element) {
                  element.scrollIntoView(true)
                }
                setMenu(false)
              }}
              to="/#history"
            >
              Our history
            </Link>
            <Link
              onClick={() => {
                var element = document.getElementById("portfolio")
                if (element) {
                  element.scrollIntoView(true)
                }
                setMenu(false)
              }}
              to="/#portfolio"
            >
              Portfolio
            </Link>
            <Link
              onClick={() => {
                var element = document.getElementById("contact")
                if (element) {
                  element.scrollIntoView(true)
                }
                setMenu(false)
              }}
              to="#contact"
            >
              Contact
            </Link>
            <Link to="/privacypolicy">Privacy policy</Link>
          </>
        )}
        {currentLang === "ee" && (
          <>
            <Link
              onClick={() => {
                var element = document.getElementById("about")
                if (element) {
                  element.scrollIntoView(true)
                }
                setMenu(false)
              }}
              to="/#about"
            >
              Meist
            </Link>
            <Link
              onClick={() => {
                var element = document.getElementById("history")
                if (element) {
                  element.scrollIntoView(true)
                }
                setMenu(false)
              }}
              to="/#history"
            >
              Meie ajalugu
            </Link>
            <Link
              onClick={() => {
                var element = document.getElementById("portfolio")
                if (element) {
                  element.scrollIntoView(true)
                }
                setMenu(false)
              }}
              to="/#portfolio"
            >
              Tooted
            </Link>
            <Link
              onClick={() => {
                var element = document.getElementById("contact")
                if (element) {
                  element.scrollIntoView(true)
                }
                setMenu(false)
              }}
              to="#contact"
            >
              Kontakt
            </Link>
            <Link to="/ee/privaatsuspoliitika">Privaatsuspoliitika</Link>
          </>
        )}
        {currentLang === "ru" && (
          <>
            <Link
              onClick={() => {
                var element = document.getElementById("about")
                if (element) {
                  element.scrollIntoView(true)
                }
                setMenu(false)
              }}
              to="/#about"
            >
              О НАС
            </Link>
            <Link
              onClick={() => {
                var element = document.getElementById("history")
                if (element) {
                  element.scrollIntoView(true)
                }
                setMenu(false)
              }}
              to="/#history"
            >
              НАША ИСТОРИЯ
            </Link>
            <Link
              onClick={() => {
                var element = document.getElementById("portfolio")
                if (element) {
                  element.scrollIntoView(true)
                }
                setMenu(false)
              }}
              to="/#portfolio"
            >
              ПОРТФОЛИО
            </Link>
            <Link
              onClick={() => {
                var element = document.getElementById("contact")
                if (element) {
                  element.scrollIntoView(true)
                }
                setMenu(false)
              }}
              to="#contact"
            >
              КОНТАКТЫ
            </Link>
          </>
        )}
        <LanguageSelection />
      </MenuInnerWrapper>
    </MenuWrapper>
  )
}

export default MenuQuery
