import { graphql, useStaticQuery } from "gatsby"
import { useEffect, useState } from "react"

const useCurrentLang = () => {
  if (typeof window === "undefined") return null
  const [currentLang, setCurrentLang] = useState(null)
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          languages
        }
      }
    }
  `)
  const { pathname } = window.location

  useEffect(() => {
    const splitted = pathname.split("/")
    let currentLang = splitted[1]
    if (currentLang === "privacypolicy") {
      currentLang = ""
    }
    setCurrentLang(currentLang)
  }, [pathname])
  return { currentLang, data }
}

export default useCurrentLang
