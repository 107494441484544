import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { useEffect } from "react"
import useCurrentLang from "../../utils/useCurrentLang"
import { LanguageWrapper, LinkWrapper } from "./styles"

const LanguageSelection = () => {
  const { currentLang, data } = useCurrentLang() || {}

  return (
    <LanguageWrapper>
      {data &&
        data.site.siteMetadata.languages.map((lang, i) => {
          return (
            <LinkWrapper
              key={i}
              current={
                currentLang
                  ? currentLang === lang
                  : lang == "en" && currentLang === ""
                  ? true
                  : false
              }
            >
              <Link to={lang !== "en" ? `/${lang}` : "/"}>{lang}</Link>
            </LinkWrapper>
          )
        })}
    </LanguageWrapper>
  )
}

export default LanguageSelection
