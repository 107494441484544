import styled from "styled-components"

export const Wrapper = styled.div`
  background-color: #f2f6fa;

  @media only screen and (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
  }
`

export const ContactsWrapper = styled.div`
  padding: 3rem;
  @media only screen and (min-width: 1024px) {
    padding: 1rem;
    flex: 0 0 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ContactsInnerWrapper = styled.div`
  @media only screen and (min-width: 1024px) {
    width: max-content;
    h2 {
      margin-bottom: 3rem;
    }
  }
`

export const ContactInfo = styled.div`
  h2 {
    color: #242424;
    font-size: 28px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 2rem;
  }
  p {
    line-height: 31px;
    color: #1e1e1e;
    margin: 0;
  }
`

export const ContactData = styled.div`
  margin-top: 3rem;

  a {
    display: block;
    text-decoration: none;
    color: #1e1e1e;
    line-height: 31px;
  }
`

export const PrivacyLinks = styled.div`
  margin-top: 1.5rem;
  a {
    display: block;
    line-height: 31px;
    text-decoration: none;
    color: #1e1e1e;
  }
`

export const Map = styled.div`
  iframe {
    margin: 0;
    border: 0;
    max-height: 350px;
  }
  @media only screen and (min-width: 1024px) {
    display: block;
    flex: 0 0 60%;
    iframe {
      max-height: unset;
    }
  }
`
