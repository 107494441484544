import React from "react"
import Iframe from "react-iframe"
import useCurrentLang from "../../utils/useCurrentLang"
import {
  ContactData,
  ContactInfo,
  ContactsInnerWrapper,
  ContactsWrapper,
  Map,
  PrivacyLinks,
  Wrapper,
} from "./styles"
import customerPrivacyEst from "../../assets/pdf/Estonian Label House - Privacy Policy for Customers and Suppliers  EST.pdf"
import employeePrivacyEst from "../../assets/pdf/Estonian Label House - Privacy Policy for Employees  EST.pdf"
import customerPrivacyEng from "../../assets/pdf/Estonian Label House - Privacy Policy for Customers and Suppliers  ENG.pdf"
import employeePrivacyEng from "../../assets/pdf/Estonian Label House - Privacy Policy for Employees ENG.pdf"

const Footer = () => {
  const { currentLang } = useCurrentLang() || {}

  return (
    <Wrapper id="contact">
      <ContactsWrapper>
        {(!currentLang || currentLang === "en" || currentLang === "") && (
          <ContactsInnerWrapper>
            <ContactInfo>
              <h2>Contacts</h2>
              <p>ESTONIAN LABEL HOUSE</p>
              <p>Õpetajate 5,</p>
              <p>31021, Kohtla-Järve,</p>
              <p>Estonia</p>
            </ContactInfo>
            <ContactData>
              <a href="tel:+ 372 33 77 500">+ 372 33 77 500</a>
              <a href="mailto:elh@elhprint.eu">elh@elhprint.eu</a>
            </ContactData>
            <PrivacyLinks>
              <a href={customerPrivacyEng} target="blank">
                Privacy policy for customers and suppliers
              </a>
              <a href={employeePrivacyEng} target="blank">
                Privacy policy for employees
              </a>
            </PrivacyLinks>
          </ContactsInnerWrapper>
        )}
        {currentLang === "ru" && (
          <ContactsInnerWrapper>
            <ContactInfo>
              <h2>Контакты</h2>
              <p>ESTONIAN LABEL HOUSE</p>
              <p>Õpetajate 5,</p>
              <p>31021, Кохтла-Ярве,</p>
              <p>Эстония</p>
            </ContactInfo>
            <ContactData>
              <a href="tel:+ 372 33 77 500">+ 372 33 77 500</a>
              <a href="mailto:elh@elhprint.eu">elh@elhprint.eu</a>
            </ContactData>
          </ContactsInnerWrapper>
        )}
        {currentLang === "ee" && (
          <ContactsInnerWrapper>
            <ContactInfo>
              <h2>Kontaktandmed</h2>
              <p>ESTONIAN LABEL HOUSE</p>
              <p>Õpetajate 5,</p>
              <p>31021, Kohtla-Järve,</p>
              <p>Eesti</p>
            </ContactInfo>
            <ContactData>
              <a href="tel:+ 372 33 77 500">+ 372 33 77 500</a>
              <a href="mailto:elh@elhprint.eu">elh@elhprint.eu</a>
            </ContactData>
            <PrivacyLinks>
              <a href={customerPrivacyEst} target="blank">
                Privaatsuspoliitika klientidele ja varustajatele
              </a>
              <a href={employeePrivacyEst} target="blank">
                Privaatsuspoliitika töötajatele
              </a>
            </PrivacyLinks>
          </ContactsInnerWrapper>
        )}
      </ContactsWrapper>
      <Map>
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2035.405800287329!2d27.425845016414566!3d59.32617858165774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469465bc676126b5%3A0xd15c5a731a735050!2s%C3%95petajate%205%2C%2031025%20Ida-Viru%20maakond!5e0!3m2!1sen!2see!4v1608194661856!5m2!1sen!2see"
          width="100%"
          height="650px"
          id="map"
          frameborder="0"
          allowfullscreen=""
          ariaHidden="false"
          tabindex="0"
          position="relative"
        />
      </Map>
    </Wrapper>
  )
}

export default Footer
