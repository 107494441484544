import styled, { css } from "styled-components"
import decor from "../../../public/assets/illustrations/banner-bg.png"

export const Wrapper = styled.div`
  background-color: #fff;
  height: 89.63px;
`

export const InnerWrapper = styled.div`
  position: relative;
  @media only screen and (min-width: 1024px) {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
  }
  @media only screen and (min-width: 1200px) {
    max-width: 1140px;
    margin: auto;
  }
  @media only screen and (min-width: 1400px) {
    max-width: 1320px;
  }
`

export const LogoWrapper = styled.div`
  position: fixed;
  display: flex;
  padding: 2rem 1rem;
  background-color: #fff;
  padding-left: 2rem;
  width: 100%;
  z-index: 5;
  @media only screen and (max-width: 413px) {
    svg {
      max-width: 200px;
    }
  }
  @media only screen and (min-width: 414px) {
  }
  @media only screen and (min-width: 1024px) {
    padding: 0;
    position: relative;
    background-color: unset;
    width: 200px;
  }
  @media (min-width: 1200px) {
    width: auto;
  }
`

export const Burger = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  height: 18px;
  width: 25px;
  span {
    transition: 0.25s ease;
    display: block;
    background-color: #0a5fc9;
    height: 3px;
    width: 100%;
    &:nth-child(1) {
      position: absolute;
      top: 0;
      transition: 0.25s ease;
    }
    &:nth-child(2) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.25s ease;
    }
    &:nth-child(3) {
      position: absolute;
      bottom: 0;
      transition: 0.25s ease;
    }
  }
  ${props =>
    props.open &&
    css`
      span {
        transition: 0.25s ease;
        &:nth-child(1) {
          top: 50%;
          transform: translateY(-50%);
          transition: 0.25s ease;
        }
        &:nth-child(3) {
          top: 50%;
          transition: 0.25s ease;
          transform: translateY(-50%);
        }
      }
    `}
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`

export const LinkWrapper = styled.div`
  a {
    display: block;
    margin: 0 1rem;
    text-decoration: none;
    color: #142d53;
    text-transform: capitalize;
    padding: 3px 5px;
  }
  ${props =>
    props.current &&
    css`
      a {
        background-color: #0a5fc9;
        color: #fff !important;
      }
    `}
`

export const MenuWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 89.63px;
  height: 0px;
  pointer-events: none;
  transition: 0.25s ease;
  background-image: url(${decor});
  z-index: -1;
  opacity: 0;
  display: flex;
  justify-content: center;
  ${props =>
    props.open &&
    css`
      pointer-events: all;
      opacity: 1;
      height: calc(100vh - 90px);
      transition: 0.25s ease;
      background-size: cover;
      z-index: 1;
    `}
  @media only screen and (min-width: 1024px) {
    width: 100%;
    opacity: 1;
    display: flex;
    top: unset;
    position: relative;
    z-index: 1;
    background-image: unset;
    pointer-events: all;
    height: auto;
    align-items: center;
    justify-content: space-between;
  }
`

export const MenuInnerWrapper = styled.div`
  margin-top: 6rem;
  a {
    text-decoration: none;
    display: block;
    color: #fff;
    text-transform: uppercase;
    line-height: 35px;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 0;
    text-align: center;
    font-size: 16px;
    transition: 0.25s ease;
    &:hover {
      color: rgb(10, 95, 201);
      transition: 0.25s ease;
    }
  }
  @media only screen and (min-width: 481px) {
    top: 97.63px;
  }
  @media only screen and (min-width: 1024px) {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    a {
      text-allign: unset;
      color: #142d53;
      display: unset;
      margin: 0 0.75rem;
      font-size: 14px;
      &:nth-child(1) {
        margin-left: auto;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    a {
      margin: 0 1rem;
      font-size: 16px;
    }
  }
  @media only screen and (min-width: 1400px) {
    a {
      margin: 0 1.75rem;
    }
  }
`
export const LanguageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  a {
    display: block;
    font-weight: 400;
    padding: 0 0.25rem;
    font-size: 14px;
    line-height: 30px;
    margin: 0 0.5rem;
  }
  @media only screen and (min-width: 1024px) {
    margin-left: auto;
    margin-top: 0;
  }
`
