import PropTypes from "prop-types"
import React from "react"
import CookieConsent from "react-cookie-consent"
import Footer from "../../Footer"
import Header from "../../Header"
import "./layout.css"

const Layout = ({ children, lang }) => {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: "#0a5fc9", textAlign: "center" }}
        buttonStyle={{ background: "#20C19E", color: "#fff" }}
      >
        {lang === "en" && (
          <>
            We use cookies to ensure that we give you the best experience on our
            website. If you continue to use this site we will assume that you
            are happy with it.
          </>
        )}
        {lang === "et" && (
          <>
            Sellel veebilehel kasutatakse küpsiseid. Veebilehe kasutamist
            jätkates nõustute küpsiste kasutamisega.
          </>
        )}
      </CookieConsent>
      <Header />
      <div>
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
